import { OrganizationSwitcher } from "@clerk/clerk-react";
import {
  DiscordLogoIcon,
  ExternalLinkIcon,
  Link2Icon,
} from "@radix-ui/react-icons";
import GitHubButton from "react-github-btn";
import { Link, useMatch } from "react-router-dom";
import { NavigationHamburgerMenu } from "./NavigationHamburgerMenu";
import { Button } from "@/components/ui/button";

function Header() {
  const match = useMatch("/workflows/:workflowPermanentId/edit");

  if (match) {
    return null;
  }

  return (
    <header>
      <div className="flex flex-col gap-4 p-4">
        <div className="flex items-center justify-center">
          <div className="flex gap-4 rounded-lg bg-slate-800 px-4 py-2">
            Skyvern 2.0 scores 85.8% on the WebVoyager benchmark
            <Button asChild size="sm" className="h-6">
              <Link
                to="https://blog.skyvern.com/skyvern-2-0-state-of-the-art-web-navigation-with-85-8-on-webvoyager-eval/"
                target="_blank"
                rel="noopener noreferrer"
              >
                See post
                <ExternalLinkIcon className="ml-2 size-4" />
              </Link>
            </Button>
          </div>
        </div>
        <NavigationHamburgerMenu />
        <div className="ml-auto flex gap-4 px-6">
          <Button
            size="sm"
            asChild
            className="text-slate-300"
            style={{
              background: "#293245",
            }}
          >
            <Link
              to="https://docs.skyvern.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Link2Icon className="mr-2 size-4" />
              API Docs
            </Link>
          </Button>
          <Button size="sm" asChild>
            <Link
              to="https://meetings.hubspot.com/skyvern/demo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExternalLinkIcon className="mr-2 size-4" />
              Book Demo
            </Link>
          </Button>
          <Link
            to="https://discord.com/invite/fG2XXEuQX3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DiscordLogoIcon className="h-7 w-7" />
          </Link>
          <div className="h-7">
            <GitHubButton
              href="https://github.com/skyvern-ai/skyvern"
              data-color-scheme="no-preference: dark; light: dark; dark: dark;"
              data-size="large"
              data-show-count="true"
              aria-label="Star skyvern-ai/skyvern on GitHub"
            >
              Star
            </GitHubButton>
          </div>
          <OrganizationSwitcher />
        </div>
      </div>
    </header>
  );
}

export { Header };
