import { useMutation, useQuery } from "@tanstack/react-query";
import { CustomerApiResponse } from "@cloud/api/types";
import { getClient } from "@/api/AxiosClient";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { Skeleton } from "@/components/ui/skeleton";
import {
  ExclamationTriangleIcon,
  InfoCircledIcon,
  MagicWandIcon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import PaymentMethodCard from "./PaymentMethodCard";
import { AddPaymentMethod } from "./AddPaymentMethod";
import { useSearchParams } from "react-router-dom";
import { toast } from "@/components/ui/use-toast";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { BillingHistory } from "./BillingHistory";
import { Input } from "@/components/ui/input";
import { useState } from "react";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function Billing() {
  const credentialGetter = useCredentialGetter();

  const { data: customer, isPending: customerIsPending } =
    useQuery<CustomerApiResponse>({
      queryKey: ["customer"],
      queryFn: async () => {
        const client = await getClient(credentialGetter);
        return await client.get("/customer").then((response) => response.data);
      },
      staleTime: 0,
      refetchOnWindowFocus: true,
    });

  const [creditAmount, setCreditAmount] = useState(10);

  const checkoutPaymentMutation = useMutation({
    mutationFn: async () => {
      const client = await getClient(credentialGetter);
      return await client.post("/checkout/payment", {
        amount: creditAmount * 100,
      });
    },
    onSuccess: (response) => {
      window.location.href = response.data.url;
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error creating payment session",
        description:
          "There was an error creating the payment session. Please try again later.",
      });
    },
  });

  const [searchParams] = useSearchParams();
  const success = searchParams.get("success") as "true" | "false" | null;
  const context = searchParams.get("context") as "setup" | "payment" | null;
  let title, description;
  if (context === "setup") {
    if (success === "true") {
      title = "Payment method added.";
      description = "You added your payment method successfully.";
    }
    if (success === "false") {
      title = "Couldn't add payment method.";
      description = "There was a problem adding your payment method.";
    }
  }
  if (context === "payment") {
    if (success === "true") {
      title = "Payment successful.";
      description = "Your payment was successful.";
    }
    if (success === "false") {
      title = "Payment cancelled.";
      description = "Your payment was cancelled.";
    }
  }

  return (
    <main className="space-y-8">
      {success !== null && context !== null && (
        <div className="w-fit pb-8">
          <Alert variant={success === "true" ? "success" : "destructive"}>
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription>{description}</AlertDescription>
          </Alert>
        </div>
      )}
      <div className="space-y-4">
        <header>
          <h1 className="text-3xl">Overview</h1>
        </header>
        <div className="space-y-4">
          <Alert className="w-96 border-slate-600">
            <MagicWandIcon className="h-4 w-4" />
            <AlertTitle>Current Plan</AlertTitle>
            <AlertDescription>Pay as you go - $0.10 per step</AlertDescription>
          </Alert>
          <div className="space-y-4">
            <h2 className="text-xl">Custom pricing</h2>
            <Alert className="w-96 border-slate-600">
              <ExclamationTriangleIcon className="h-4 w-4" />
              <AlertTitle>Need a custom plan?</AlertTitle>
              <AlertDescription>
                <a
                  className="underline"
                  href="https://meetings.hubspot.com/skyvern/demo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book a call
                </a>{" "}
                to discuss
              </AlertDescription>
            </Alert>
          </div>
          <div>
            <div className="flex items-center gap-2">
              <h2 className="text-xl">Credit Balance</h2>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <InfoCircledIcon />
                  </TooltipTrigger>
                  <TooltipContent className="max-w-[250px]">
                    <p>
                      You will be charged from your credit balance as you run
                      tasks.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            {customerIsPending ? (
              <Skeleton className="h-8 w-40" />
            ) : customer ? (
              <div className="text-3xl">
                {currencyFormatter.format(customer.credit_balance / 100)}
              </div>
            ) : (
              <span>Could not fetch credit balance</span>
            )}
          </div>

          <div className="flex items-center gap-2">
            <div className="relative">
              <span className="absolute left-2 top-1/2 -translate-y-1/2">
                $
              </span>
              <Input
                type="number"
                placeholder="10"
                className="nopan w-52 pl-6 text-xs"
                min="0"
                value={creditAmount}
                onChange={(event) => {
                  setCreditAmount(Number(event.target.value));
                }}
              />
            </div>
            <Button
              onClick={() => {
                checkoutPaymentMutation.mutate();
              }}
              disabled={checkoutPaymentMutation.isPending}
            >
              {checkoutPaymentMutation.isPending && (
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
              )}
              Load Credits
            </Button>
            <span className="text-xs">
              You will be redirected to Stripe for payment.
            </span>
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <header>
          <h1 className="text-3xl">Payment Methods</h1>
        </header>
        <div>
          <div className="flex gap-8">
            {!customerIsPending && <AddPaymentMethod />}
            {customerIsPending ? (
              <>
                <Skeleton className="h-24 w-64" />
                <Skeleton className="h-24 w-64" />
              </>
            ) : (
              customer?.payment_methods.map((paymentMethod, index) => {
                return (
                  <PaymentMethodCard
                    key={index}
                    brand={paymentMethod.card.brand}
                    expMonth={paymentMethod.card.exp_month}
                    expYear={paymentMethod.card.exp_year}
                    last4={paymentMethod.card.last4}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <header>
          <h1 className="text-3xl">Billing History</h1>
        </header>
        <BillingHistory />
      </div>
    </main>
  );
}

export { Billing };
