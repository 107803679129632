type Props = {
  className?: string;
};

function BrainIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M16.9979 7.127C17.3193 7.04234 17.6533 7 17.9999 7C18.5686 7.00059 19.1306 7.12242 19.6485 7.35737C20.1663 7.59232 20.6281 7.93499 21.0031 8.36253C21.3781 8.79006 21.6576 9.29263 21.8229 9.83672C21.9883 10.3808 22.0358 10.9539 21.9622 11.5178C21.8886 12.0817 21.6956 12.6234 21.396 13.1068C21.0965 13.5902 20.6974 14.0042 20.2252 14.3211C19.7531 14.638 19.2188 14.8507 18.658 14.9448C18.0971 15.039 17.5227 15.0124 16.9729 14.867M16.9979 7.127L16.9999 7C17.0015 6.01205 16.6374 5.05848 15.9777 4.323C15.3181 3.58752 14.4096 3.12218 13.4273 3.01662C12.445 2.91106 11.4584 3.17276 10.6576 3.7513C9.85673 4.32984 9.29833 5.18428 9.08994 6.15M16.9979 7.127C16.9773 7.78571 16.7942 8.42911 16.4649 9M16.9729 14.867C16.9909 14.747 16.9999 14.6247 16.9999 14.5C17.0001 13.9237 16.801 13.365 16.4366 12.9186C16.0721 12.4721 15.5646 12.1653 14.9999 12.05M16.9729 14.867C16.8849 15.46 16.5868 16.0016 16.1329 16.3931C15.6789 16.7846 15.0994 17 14.4999 17H13.9999C12.9391 17 11.9217 17.4214 11.1715 18.1716C10.4214 18.9217 9.99994 19.9391 9.99994 21M9.08994 6.15C8.40228 5.95474 7.67487 5.94733 6.98338 6.12853C6.29188 6.30973 5.66158 6.67293 5.15806 7.18033C4.65453 7.68774 4.29619 8.3208 4.1203 9.01367C3.94441 9.70653 3.9574 10.4339 4.15794 11.12M9.08994 6.15C10.0923 6.43386 10.9444 7.09759 11.4649 8M4.15794 11.12C3.46598 11.3236 2.87149 11.7695 2.48147 12.3763C2.09145 12.983 1.933 13.7099 2.03512 14.4239C2.13725 15.1379 2.49311 15.7913 3.03757 16.2643C3.58203 16.7374 4.27866 16.9986 4.99994 17C5.62059 17.0003 6.22607 16.8081 6.73292 16.4499C7.23977 16.0917 7.62305 15.5852 7.82994 15M4.15794 11.12C4.24861 11.4313 4.37494 11.7247 4.53494 12M11.8359 11.744C11.3259 12.235 10.4529 12.32 9.70694 11.901C8.96094 11.481 8.57994 10.691 8.73494 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { BrainIcon };
